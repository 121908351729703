import React from "react"
import { EmailQuoteRequestStatusProvider } from "./src/components/context/EmailQuoteRequestStatusProvider"

import { CustomThemeWrapper } from "./src/components/CustomThemeWrapper"

export const wrapPageElement = ({ element }) => (
  <CustomThemeWrapper>
    <EmailQuoteRequestStatusProvider>{element}</EmailQuoteRequestStatusProvider>
  </CustomThemeWrapper>
)
