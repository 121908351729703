import React, { createContext, useState, useContext } from "react"

const EmailQuoteRequestStatusContext = createContext({
  emailQuoteRequestStatus: false,
  setEmailQuoteRequestStatus: () => {},
})

export const EmailQuoteRequestStatusProvider = ({ children }) => {
  const [emailQuoteRequestStatus, setEmailQuoteRequestStatus] = useState(false)
  return (
    <EmailQuoteRequestStatusContext.Provider
      {...{
        value: {
          emailQuoteRequestStatus,
          setEmailQuoteRequestStatus,
        },
      }}
    >
      {children}
    </EmailQuoteRequestStatusContext.Provider>
  )
}

export const useEmailQuoteRequestStatusContext = () =>
  useContext(EmailQuoteRequestStatusContext)
